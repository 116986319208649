import Link from 'next/link'
import { FC, ReactNode } from 'react'

import Icon from '../Icon'

import styles from './Card.module.scss'

export interface CardProps {
  imageSrc: string
  title: string
  subtitle?: ReactNode | string
  link: string
  linkTitle: string
  aspectRatio?: string
}

const Card: FC<CardProps> = ({
  link,
  title,
  subtitle,
  imageSrc,
  linkTitle,
  aspectRatio = '3 / 2',
}) => (
  <Link href={link}>
    <a className={styles.link}>
      <div className={styles.card}>
        <div className={styles['image-container']}>
          <img src={imageSrc} className={styles.image} alt={title} style={{ aspectRatio }} />
        </div>
        <span className={styles.title}>{title}</span>
        {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        <div>
          <span>{linkTitle}</span>
          <Icon name="right-arrow" height="0.7em" width="0.7em" className="inline" />
        </div>
      </div>
    </a>
  </Link>
)

export default Card
