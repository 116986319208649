import { ReactNode, PropsWithChildren, isValidElement } from 'react'

import C, { CarouselProps } from '~shared/Carousel'
import { useScreenClass } from 'hooks'
import { itemsToShow } from '~utils'
import { Grid, Placeholder } from '~ui'

import styles from './carousel.module.scss'

type CarouselP<T> = {
  renderItem: (data: T, index: number) => ReactNode
  title?: string | ReactNode
  dataSource: T[]
  maxItemsToShow?: number
  placeholderItem?: ReactNode
  loading?: boolean
  fetchMore?: () => void | null // make sure it's null if no more items left to fetch
} & Pick<CarouselProps, 'interval'>

const Carousel = <T extends object>({
  dataSource,
  maxItemsToShow,
  placeholderItem,
  renderItem,
  title,
  interval,
  fetchMore,
  loading,
}: PropsWithChildren<CarouselP<T>>) => {
  const { screenClass } = useScreenClass()

  const showControls =
    !['sm', 'xs'].includes(screenClass) &&
    dataSource?.length > itemsToShow(screenClass, maxItemsToShow)

  return (
    <div className={styles.wrapper}>
      {title && isValidElement(title) && title}
      {title && !isValidElement(title) && <div className={styles.title}>{title}</div>}

      <C
        loading={loading}
        interval={interval}
        fetchMore={fetchMore}
        placeholderItem={
          placeholderItem || (
            <Grid gap={15} width="100%" height="100%">
              <Placeholder style={{ height: 200 }} />
              <Placeholder style={{ height: 20, width: '80%' }} />
              <Placeholder style={{ height: 15, width: '50%' }} />
            </Grid>
          )
        }
        showControls={showControls}
        itemsToShow={itemsToShow(screenClass, maxItemsToShow)}
      >
        {dataSource?.map(renderItem)}
      </C>
    </div>
  )
}

export default Carousel
