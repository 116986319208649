/* eslint-disable react/no-array-index-key */
import classNames from 'classnames'
import {
  useState,
  useRef,
  Children,
  FC,
  useEffect,
  useCallback,
  useMemo,
  CSSProperties,
  ReactNode,
} from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { IconButton } from '~ui'

import styles from './Carousel.module.scss'

export interface CarouselProps {
  loading?: boolean
  interval?: number
  itemsToShow: number
  showControls?: boolean
  fetchMore?: () => void | null // make sure it's null if no more items left to fetch
  hideScrollBar?: boolean
  placeholderItem?: ReactNode
}

const PLACEHOLDER_COUNT = 5
const PIXELS_LEFT_TO_SCROLL_TO_END = 10

const Carousel: FC<CarouselProps> = ({
  interval,
  children,
  fetchMore,
  itemsToShow = 1,
  loading,
  placeholderItem,
  showControls = true,
  hideScrollBar = true,
}) => {
  const [index, setIndex] = useState(0)
  const containerRef = useRef<HTMLDivElement>()
  const setTimeoutRef = useRef<NodeJS.Timeout>()

  const itemsCount = useMemo(() => Children.count(children), [children])

  const onNext = useCallback(() => {
    const itemWidth = containerRef.current?.children[0]?.clientWidth

    const element = containerRef.current

    const scrolledToEnd =
      element.scrollWidth - element.clientWidth - element.scrollLeft < PIXELS_LEFT_TO_SCROLL_TO_END

    if (!loading && scrolledToEnd && !fetchMore) {
      containerRef.current.scrollTo({
        behavior: 'smooth',
        left: 0,
      })
      return
    }

    containerRef.current.scrollBy({ left: itemWidth, behavior: 'smooth' })
  }, [itemsCount, itemsToShow, loading])

  const onBack = useCallback(() => {
    const itemWidth = containerRef.current?.children[0]?.clientWidth

    if (loading) return

    const element = containerRef.current

    if (element.scrollLeft < PIXELS_LEFT_TO_SCROLL_TO_END) {
      containerRef.current.scrollTo({
        behavior: 'smooth',
        left: element.scrollWidth,
      })
      return
    }

    containerRef.current.scrollBy({
      behavior: 'smooth',
      left: -itemWidth,
    })
  }, [itemsCount, itemsToShow, loading])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (interval) {
      setTimeoutRef.current = setTimeout(() => {
        onNext()
      }, interval * 1000)

      return () => {
        if (setTimeoutRef.current) clearTimeout(setTimeoutRef.current)
      }
    }
  }, [index])

  const scroll = (e) => {
    const element = e.target
    const itemWidth = containerRef.current?.children[0]?.clientWidth

    const scrolledToFetchMore =
      element.scrollWidth - element.clientWidth - element.scrollLeft < itemWidth // prefetch one item before

    if (!loading && scrolledToFetchMore) {
      fetchMore?.()
    }

    const i = (element.scrollLeft + element.clientWidth) / itemWidth

    setIndex(Math.floor(i))
  }

  const scrollDeb = useDebouncedCallback(scroll, 100)

  useEffect(() => {
    containerRef.current?.addEventListener('scroll', scrollDeb)
    return () => containerRef.current?.removeEventListener('scroll', scrollDeb)
  }, [loading, fetchMore])

  const carouselItemsJSX =
    Children.map(children, (child, i) => (
      <div
        key={i}
        style={
          {
            '--item-width': `calc(${100 / itemsToShow}% ${itemsToShow === 1 ? '- 60px' : ''})`,
          } as CSSProperties
        }
        className={classNames(styles.item)}
      >
        {child}
      </div>
    )) || []

  const noItemsLoadingPlaceholderJSX = [
    ...Array(PLACEHOLDER_COUNT)
      .fill(0)
      .map((_, i) => (
        <div
          key={i + itemsCount}
          className={classNames(styles.item, {
            [styles.last]: i === itemsCount + PLACEHOLDER_COUNT - 1,
          })}
          style={
            {
              '--item-width': `${100 / itemsToShow}%`,
            } as CSSProperties
          }
        >
          {placeholderItem}
        </div>
      )),
  ]

  const loadingMorePlaceholderJSX = [
    ...carouselItemsJSX,
    <div
      key={itemsCount}
      className={classNames(styles.item, styles.last)}
      style={
        {
          '--item-width': `${100 / itemsToShow}%`,
        } as CSSProperties
      }
    >
      {placeholderItem}
    </div>,
  ]

  const showInitialLoading = !itemsCount && loading
  const showLoadingPlaceholder = itemsCount && loading
  const showItems = itemsCount && !loading

  return (
    <>
      <div className={styles.carousel}>
        {showControls && <IconButton iconName="left-arrow" onClick={onBack} />}

        <div aria-hidden className={styles.wrapper}>
          <div
            ref={containerRef}
            className={classNames(styles.container, {
              [styles['hide-scrollbar']]: hideScrollBar,
            })}
          >
            {showInitialLoading ? noItemsLoadingPlaceholderJSX : null}
            {showLoadingPlaceholder ? loadingMorePlaceholderJSX : null}
            {showItems ? carouselItemsJSX : null}
          </div>
        </div>

        {showControls && <IconButton disabled={loading} iconName="right-arrow" onClick={onNext} />}
      </div>
    </>
  )
}

export default Carousel
