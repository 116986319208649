import classnames from 'classnames'
import { FC, createRef, useEffect } from 'react'

import styles from './GlowHeading.module.scss'

type HeadingVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'span'

interface StylesClasses {
  className?: string
  color?: GThemeColorsNew
  uppercase?: boolean
  center?: boolean
  size?: HeadingVariants
  fill?: boolean
  glow?: boolean
  font?: 'Poppins'
  bold?: boolean
}

export interface GlowHeadingProps extends StylesClasses {
  Tag: HeadingVariants
}

const classes = ({
  color = 'purple',
  className,
  uppercase,
  center,
  size,
  fill,
  font,
  bold,
  glow = true,
}: StylesClasses) =>
  classnames(styles.heading, styles[size], {
    [styles[`heading-${color}`]]: glow && color,
    [styles['heading-glow']]: glow,
    [styles.uppercase]: uppercase,
    [styles.fill]: fill,
    [styles.center]: center,
    [styles.poppins]: font === 'Poppins',
    [styles.bold]: bold,
    [className]: className,
  })

const GlowHeading: FC<GlowHeadingProps> = ({ children, Tag, ...classNames }) => {
  const hRef = createRef<HTMLHeadingElement>()

  useEffect(() => {
    if (hRef.current) hRef.current.dataset.content = `${children}`
  }, [children])

  return (
    <Tag ref={hRef} className={classes(classNames)}>
      {children}
    </Tag>
  )
}

export default GlowHeading
