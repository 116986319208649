import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import styles from './Wrapper.module.scss'

const Wrapper = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }, ref) => (
    <div className={classNames(styles.wrapper, className)} ref={ref} {...props}>
      {children}
    </div>
  )
)

export default Wrapper
