import { useLayoutEffect, useState, useRef } from 'react'

const useOnScreen = (ref = undefined, rootMargin = '0px') => {
  const innerRef = useRef()

  const [isIntersecting, setIntersecting] = useState(false)
  const [wasViewed, setWasViewed] = useState(false)

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting)
        if (entry.isIntersecting) {
          setWasViewed(true)
        }
      },
      {
        rootMargin,
      }
    )

    if (ref?.current) {
      observer.observe(ref.current)
      return () => {
        observer.unobserve(ref.current)
      }
    }

    if (innerRef.current) {
      observer.observe(innerRef.current)
      return () => {
        observer.unobserve(innerRef.current)
      }
    }

    return () => null
  }, [])

  return { innerRef, isIntersecting, wasViewed }
}

export default useOnScreen
